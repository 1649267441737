import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Menu, MenuItem, Theme,
} from
  '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    color: theme.palette.common.black,
  },
  hideOnXS: {
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

const NavMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const { t, i18n } = useTranslation(['main']);

  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        aria-label="menu"
        onClick={handleClickMenu}
        size="large"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => { history.push('/'); setAnchorEl(null); }}>
        {t('appbar.home')}</MenuItem>
        <MenuItem onClick={() => { history.push('/tausta'); setAnchorEl(null); }}>
        {t('appbar.background')}</MenuItem> */}
        <MenuItem onClick={() => { history.push('/kehitys'); setAnchorEl(null); }}>{t('appbar.development')}</MenuItem>
        <MenuItem onClick={() => { history.push('/laskentatavat'); setAnchorEl(null); }}>{t('appbar.methods')}</MenuItem>
        <MenuItem onClick={() => { history.push('/ajankohtaista'); setAnchorEl(null); }}>{t('appbar.news')}</MenuItem>
        {user && user.groups?.includes('Users') && (<MenuItem onClick={() => { history.push('/company'); setAnchorEl(null); }}>{t('appbar.company')}</MenuItem>)}
        {user && user.groups?.includes('Admins') && (<MenuItem onClick={() => { history.push('/admin'); setAnchorEl(null); }}>{t('appbar.admin')}</MenuItem>)}
        {i18n.language !== 'fi' && (<MenuItem onClick={() => i18n.changeLanguage('fi')}>{t('Suomeksi')}</MenuItem>)}
        {i18n.language !== 'sv' && (<MenuItem onClick={() => i18n.changeLanguage('sv')}>{t('På Svenska')}</MenuItem>)}
        {i18n.language !== 'en' && (<MenuItem onClick={() => i18n.changeLanguage('en')}>{t('In English')}</MenuItem>)}
      </Menu>
    </>
  );
};

export default NavMenu;
